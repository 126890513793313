export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&*@^])(?=.{8,})/

export const PHONE_REGEX = /(0)+([0-9]{9})\b/

export const USERNAME_REGEX = /^[a-z0-9]+(?:(?:-|_)+[a-z0-9]+)*$/

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const USERNAME_EMAIL_REGEX =
  /^(?:([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})|([a-z0-9]+(?:-[a-z0-9]+)*))$/
