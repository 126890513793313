import {
  PASSWORD_REGEX,
  PHONE_REGEX,
  USERNAME_REGEX,
  EMAIL_REGEX,
  USERNAME_EMAIL_REGEX,
} from '@/constants/index'

export const validatePhone = (phoneNumber) => {
  return PHONE_REGEX.test(phoneNumber)
}

export const validatePassword = (password) => {
  return PASSWORD_REGEX.test(password)
}

export const validateUsername = (username) => {
  return USERNAME_REGEX.test(username)
}

export const validateEmail = (email) => {
  return EMAIL_REGEX.test(email)
}

export const validateUsernameOrEmail = (emailOrUsername) => {
  return USERNAME_EMAIL_REGEX.test(emailOrUsername)
}
